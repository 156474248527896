import React, { useEffect, useRef, useCallback} from 'react';
import * as PIXI from 'pixi.js'
import useWindowSize from '../hooks/useWindowSize';
import {Storage} from 'aws-amplify';

const animatedSprites = [
  "appear",
  "baseball_guy_away",
  "baseball_guy_facing",
  "field",
  "guy_appear",
  "guy_disappear",
  "guy_hit_init",
  "guy_hit_release",
  "hit_1",
  "hit_2",
  "hit_3",
  "homebase",
  "outfielder_1_appear",
  "outfielder_1_disappear",
  "outfielder_1_static",
  "outfielder_2_appear",
  "outfielder_2_disappear",
  "outfielder_2_static",
  "outfielder_3_appear",
  "outfielder_3_disappear",
  "outfielder_3_static",
  "pitch_stand",
  "pitchers_mound",
  "platform",
  "release",
  "singing_baseball_blink",
  "singing_baseball_sing",
  "singing_baseball_sing2",
  "singing_baseball_static",
  "throw_1_init",
  "throw_1_release",
  "throw_2_init",
  "throw_2_release",
  "throw_3_init",
  "throw_3_release",
  "throw",
  "wind_up"
]

const textures = [
  "star",
  "milkyway_tile",
  "moon",
  "mountains",
  "planet"
]

const outfielderAnimations = ['appear', 'disappear', 'static']

let jsonDict = {}

const minWidth = 900 

const fontColor = "LightSkyBlue"

const randArrVal = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const PIXIContainer = () => {
  const [width, height] = useWindowSize()
  const appRef = useRef();
  const divRef = useRef();
  const assetsRef = useRef({});
  const gameRef = useRef({});
  const animationRef = useRef({});

  const makeGame = useCallback(async function makeGame () {
    async function loadAsset (file) {
      try {
        return await Storage.get(file)
      } catch (err) {
        return err
      }
    }
  
    async function fetchJSON (jsonURL) {
      jsonURL = await fetch(jsonURL)
      const data = await jsonURL.json()
      return data
    } 
  
    async function loadAssets ({type, items, message} ) { 
        let assets = []
        let goal = items.length;
        let progress = 0.0;
        alterLoadingText(message, goal, progress)
        for (const item of items) {
          console.log("loading " + item)
          const path = 'game_assets/' + item
          const imgURL = await loadAsset(path + ".png")
          let itemData = {key: item, imgURL: imgURL}
          if (type === 'animated') {
            const jsonURL = await loadAsset(path + ".json")
            const json = await fetchJSON(jsonURL)
            itemData = {...itemData, json: json}
            jsonDict = {...jsonDict, [item]: itemData}
          } 
          PIXI.Loader.shared.add(item, imgURL)
          assets = [...assets, itemData]
          progress++
          alterLoadingText(message, goal, progress)
        }
        return assets
    }
  
    const buildAnimatedSpirte = async (spriteData, resources, cloneID = "newSprite") => {
      const data = spriteData.json
      const name = spriteData.key
      const spritesheet = new PIXI.Spritesheet(
        resources[name].texture,
        data
      );
      try {
        spritesheet.parse((...args) => {
          const frameCount = Object.keys(data.frames).length
          const frames = args[0];
          const textures = [];
          for (let i = 0; i < frameCount; i++) {
            let frame =  "000" + i;
            if (i < 10) {
              frame = "0" + frame
            }
            textures.push(frames[name + "_" + frame + ".png"])
          }
          assetsRef.current[name] = new PIXI.AnimatedSprite(textures)
          return "success"
        })
      } catch (err) {
        return (err)
      }
    }
  
    const alterLoadingText = (text, goal, progress) => {
      if (assetsRef.current.hasOwnProperty('loadingText')) {
        let percentage = Math.round((progress / goal) * 100)
        if (percentage < 10) {
          percentage = "0" + percentage
        } 
        assetsRef.current['loadingText'].text = text + " : " + percentage + "%"
      }
    } 
  
    const buildLoadingScreen = (app, width, height) => {
      if (!assetsRef.current.hasOwnProperty('loadingScreen')) {
        const loadingContainer = new PIXI.Container();
        const loadingText = new PIXI.Text('', {
          fontFamily: 'Major Mono Display',
          fontSize: 20,
          fill: fontColor,
          align: 'left',
        });
        loadingText.anchor.x = 0.5
        loadingText.anchor.y = 0.5
        loadingContainer.addChild(loadingText)
        app.stage.addChild(loadingContainer)
        assetsRef.current['loadingScreen'] = loadingContainer
        assetsRef.current['loadingText'] = loadingText
      }
      assetsRef.current['loadingText'].position.set(width / 2, height / 2)
    }
  
    const createContainer = (name, assets, sprites) => {
      const app = appRef.current
      const container = new PIXI.Container()
      if (sprites) {
        sprites.forEach(sprite => {
          container.addChild(sprite)
        });
      }
      assets.forEach(asset => {
        container.addChild(assetsRef.current[asset])
      });
      app.stage.addChild(container)
      assetsRef.current[name] = container
    }
  
    const containsAsset = (name) =>  assetsRef.current.hasOwnProperty(name)
    const getAsset = (name) =>  assetsRef.current[name]
    const setAsset = (name, data) =>  assetsRef.current[name] = data
  
    const buildLevel = async(update, assets, name, sprites, animations) => {
      if (!containsAsset(name)) {
        animations()
        createContainer(name, assets, sprites)
        update()
      } else {
        update()
      }
    }
  
    const setAnimationParams = ({assetName, speed, x, y, scale, play}) => {
      const asset = getAsset(assetName)
      if (play) {
        asset.play()
      }
      asset.animationSpeed = speed
      asset.anchor.x = 0.5
      asset.anchor.y = 0.5
      asset.width = 512 * scale
      asset.height = 512 * scale
      asset.position.x = x
      asset.position.y = y
    }
  
    const setStaticParam = ({assetName, x, y, scale}) => {
      const asset = getAsset(assetName)
      asset.anchor.x = 0.5
      asset.anchor.y = 0.5
      asset.width = 512 * scale
      asset.height = 512 * scale
      asset.position.x = x
      asset.position.y = y
    }

    const setAssets = (sprites, func) => sprites.forEach(func)

    const cloneAnimatedSprite = (asset, cloneID = "newSprite") => {
      assetsRef.current[cloneID] = new PIXI.AnimatedSprite(getAsset(asset).textures)
    }

    const getGameData = (prop) => {
      const gameData = gameRef.current
      if (gameData.hasOwnProperty(prop)) {
        return gameData[prop]
      } else {
        return NaN
      }
    }

    const setGameData = (prop, value) => gameRef.current[prop] = value
  
    const getAnimationData = (prop) => {
      const gameData = animationRef.current
      if (gameData.hasOwnProperty(prop)) {
        return gameData[prop]
      } else {
        return NaN
      }
    }
    const setAnimationData = (prop, value) => animationRef.current[prop] = value

    const setPlatformPosition = (i) => {
      const count = getAnimationData('platform_counter_' + i)
      const amp = getAnimationData('platform_amp_' + i)
      const speed = getAnimationData('platform_speed_' + i)
      const delta_y = amp * Math.cos(2 * Math.PI * (count) / speed) 
      setAnimationData('platform_delta_y_' + i, delta_y)
    }

    const buildBaseballGame = async () => {

      const updateBaseballGuy = () => {
        setAssets([
          'guy_hit_init', 
          'guy_hit_release', 
          'baseball_guy_away', 
          'guy_disappear', 
          'guy_appear'
        ], (sprite) => {
          setAnimationParams({
            assetName: sprite,
            speed: 0.1,
            scale: 1.2,
            x: width / 2 - 50,
            y: height - 300, 
            play: false
          })
        })
        const swingSpeed = 0.3
        getAsset('guy_hit_init').animationSpeed = swingSpeed
        getAsset('guy_hit_release').animationSpeed = swingSpeed
        getAsset('baseball_guy_away').play()
      }

      const adjustToMinWidth = (asset) => {
        getAsset(asset).anchor.x = 0
        getAsset(asset).anchor.y = 0
        if (width < minWidth) {
          getAsset(asset).width = minWidth
          getAsset(asset).position.x = (width - minWidth) / 2
        } else {
          getAsset(asset).width = width
          getAsset(asset).position.x = 0
        }
      }
      const adjustHeight = (asset, assetHeight, shift) => {
        getAsset(asset).height = assetHeight
        getAsset(asset).position.y = height - assetHeight - shift
      }

      const updateField = () => {
        setAnimationParams({
          assetName: 'field',
          speed: 0.1,
          x: 0,
          y: 0,
          play: true
        })
        adjustToMinWidth('field')
        adjustHeight('field', 250, 0)
        setAnimationParams({
          assetName: 'homebase',
          speed: 0.1,
          x: width / 2, 
          y: height - 90,
          play: true
        })
        getAsset('homebase').width = 850
        getAsset('homebase').height = 400
        setAnimationParams({
          assetName: 'pitchers_mound',
          speed: 0.1,
          scale: 1.0,
          x: width / 2, 
          y: height - 230,
          play: true
        })
        getAsset('pitchers_mound').width = 220
        getAsset('pitchers_mound').height = 150
      }

      const updateMountains = () => {
        const mountains = getAsset('mountains')
        adjustToMinWidth('mountains')
        adjustHeight('mountains', 250, 250)
        mountains.tint = Math.random() * 0xFFFFFF;
      }
  
      const updatePlanet = () => {
        setStaticParam({
          assetName: 'planet',
          x: width / 4 * 3 + 90,
          y: 220,
          scale: 0.8
        })
        getAsset('planet').tint = Math.random() * 0xFFFFFF;
      }
  
      const updateMoon = () => {
        setStaticParam({
          assetName: 'moon',
          x: width / 4 - 90,
          y: 100,
          scale: 0.25
        })
        getAsset('moon').tint = Math.random() * 0xFFFFFF;
      }

      const setBaseballThrow = (asset) => {
        setAnimationParams({
          assetName: asset,
          speed: 0.15,
          scale: 1,
          x: width / 2 + 20,
          y: height - 300,
          play: false
        })
        const baseballscale = 2.0
        const baseWidth = 400
        const baseHeight = 250
        getAsset(asset).width = baseWidth * baseballscale
        getAsset(asset).height = baseHeight * baseballscale
      }

      const setBaseballHit = (asset) => {
        setAnimationParams({
          assetName: asset,
          speed: 0.15,
          scale: 1,
          x: width / 2,
          y: height - 300,
          play: false
        })
        const baseballscale = 2.0
        const baseWidth = 400
        const baseHeight = 250
        getAsset(asset).width = baseWidth * baseballscale
        getAsset(asset).height = baseHeight * baseballscale
      }

      const updatePitcher = () => {
        setAssets(['pitch_stand', 'wind_up', 'release', 'appear'], sprite => {
          const scale = 0.35
          setAnimationParams({
            assetName: sprite,
            speed: 0.1,
            scale: scale,
            x: width / 2,
            y: height - 265,
            play: false
          })
        })        
        setAssets([
          'throw_1_init', 
          'throw_1_release', 
          'throw_2_init', 
          'throw_2_release',
          'throw_3_init', 
          'throw_3_release'
        ], sprite => {
          setBaseballThrow(sprite)
        })
        setAssets([
          'hit_1', 
          'hit_2',
          'hit_3'
        ], sprite => {
          setBaseballHit(sprite)
        })
        getAsset('hit_3').y = height - 350
        getAsset('pitch_stand').play()
      }
  
      const createStars = (starNum) => {
        if (!containsAsset('starsArr')) {
          let starsArr = []
          for (let i = 0; i < starNum; i++) {
            const thisStar = new PIXI.Sprite(PIXI.Loader.shared.resources['star'].texture)
            thisStar.anchor.x = 0.5
            thisStar.anchor.y = 0.5
            thisStar.width = Math.random() + 0.5
            thisStar.height = Math.random() + 0.5
            thisStar.position.x = Math.random() * width
            thisStar.position.y = Math.random() * height / 2
            starsArr = [...starsArr, thisStar]
          }
          assetsRef.current['starsArr'] = starsArr
        } else {
          getAsset('starsArr').forEach(star => {
            star.position.x = Math.random() * width
            star.position.y = Math.random() * height / 2    
          })
        }
      }

      const updatePlatforms = () => {
        const platformHeight = height * 3 / 4
        const spread = width / 3 + 40
        let platformData = [
          {
            x: spread, 
            y: platformHeight, 
            scale: 0.3, 
            speed: 0.05, 
            phase: 23, 
            amp: 10, 
            movementSpeed: 200, 
            index: 0, 
            color: 0x0000FF
          },
          {
            x: 0, 
            y: platformHeight + 30,
            scale: 0.2, 
            speed: 0.01, 
            phase: 10, 
            amp: 5, 
            movementSpeed: 250, 
            index: 1, 
            color: 0xFF00FF
          },
          {
            x: - spread, 
            y: platformHeight, 
            scale: 0.3, 
            speed: 0.03, 
            phase: 0, 
            amp: 10, 
            movementSpeed: 200, 
            index: 2, 
            color: 0xff0000
          }
        ]
        const setPlatformParams = ({x, y, scale, speed, phase, amp, movementSpeed, index, color}) => {
          if (!containsAsset('numPlatforms')) {
            setAnimationData('platform_counter_' + index, phase)
            setAnimationData('platform_amp_' + index, amp)
            setAnimationData('platform_speed_' + index, movementSpeed)
            setAnimationData('platform_init_y_' + index, height - y)
            console.log('platform_init_y_' + index + ":" + getAnimationData('platform_init_y_' + index))
            setPlatformPosition(index)
          }
          setAnimationParams({
            assetName: 'platform_' + index,
            speed: speed,
            scale: scale,
            x: width / 2 + x,
            y: getAnimationData('platform_init_y_' + index) + getAnimationData('platform_delta_y_' + index),
            play: true
          })
          getAsset('platform_' + index).tint = color;
        }
        const createPlatform = (data) => {
          const platformName = 'platform_' + data.index
          cloneAnimatedSprite(
            'platform',
            platformName
          );
          setPlatformParams(data)
        }
        if (!containsAsset('numPlatforms')) {
          for(let i = 0; i < platformData.length; i++) {
            createPlatform(platformData[i])
          }
          setAsset('numPlatforms', platformData.length)
        } else {
          for(let i = 0; i < platformData.length; i++) {
            setPlatformParams(platformData[i])
          }
        }
      }
  
      const update = async () => {
        createStars(2000)
        updatePlatforms()
        updateField()
        updateBaseballGuy()
        updateMountains()
        updatePlanet()
        updateMoon()
        updatePitcher()
      }
      createStars(2000)
      updatePlatforms()

      const makeAnimation = () => {
        const actionList = ['touchstart', 'click', 'keypad']

        const hideSprites = () => {
          const hiddenSprites = [
            'wind_up',
            'release',
            'appear',
            "guy_hit_init",
            "guy_hit_release",
            'throw_1_init',
            'throw_1_release',
            'throw_2_init',
            'throw_2_release',
            'throw_3_init',
            'throw_3_release',
            'hit_1',
            'hit_2',
            'hit_3',
          ]
          hiddenSprites.forEach(spriteName => {
            const sprite = getAsset(spriteName)
            console.log(sprite.currentFrame)
            sprite.visible = false
            sprite.loop = false
          })
        }  

        // const getRandTime = (minTime, maxTime) => Math.max(Math.random() * maxTime, minTime)
        const addTimeout  = (func, time) => window.setTimeout(func, time * 1000)
        // const removeTimeout  = (timeout) => window.clearTimeout(timeout)
        const addAction = (func) => {
          actionList.forEach(action => {
            window.addEventListener(action, func)
          })
        }
        const removeAction = (func) =>{
          actionList.forEach(action => {
            window.removeEventListener(action, func)
          })
        }
        const setVisible = (sprite, isVisible) => getAsset(sprite).visible = isVisible

        const playNext = (prev, next) => {
          console.log('playing ' + next)
          setVisible(prev, false)
          setVisible(next, true)
          getAsset(next).gotoAndPlay(0)
        }

        const addOnComplete = (sprite, func) => {
          getAsset(sprite).onComplete = func
        }
    
        const updateScore = (points) => {
          clearOutfielders();
          const curBases = [...getGameData('bases')]

          addTimeout(() => {
            for (let i = curBases.length - 1; i >= 0; i--) {
              const base = curBases[i]
              if (base.baseLoaded) {
                if ((i + points) < curBases.length) {
                  positionOutfielder(i, i + points, curBases)
                } else {
                  eliminateOutfielder(i)
                  setGameData('score', getGameData('score') + 1)
                }
              }
            }
            addTimeout(() => {
              for (let i = 0; i < curBases.length; i++) {
                const {type, id, baseLoaded} = getGameData('bases')[i]
                if (baseLoaded) {
                  console.log('base ' + i + " loaded")
                  setVisible('outfielder_' + type + '_appear_' + id, true)
                  getAsset('outfielder_' + type + '_appear_' + id).gotoAndPlay(0)
                }
              }
              if (points - 1 < curBases.length) {
                console.log("creating Outfielder")
                createOutfielder(points - 1)
              } else {
                setGameData('score', getGameData('score') + 1)
              }
              console.log("score: " + getGameData('score'))
            }, 2.0)
          }, 2.0)
        }

        const clearOutfielders = () => {
          getGameData('bases').forEach(base => {
            const {type, id, baseLoaded} = base
            if (baseLoaded) {
              setVisible(('outfielder_' + type + "_static_" + id), false)
              setVisible(('outfielder_' + type + "_disappear_" + id), true)
              getAsset('outfielder_' + type + "_disappear_" + id).gotoAndPlay(0)
            }
          })
        }

        const eliminateOutfielder = (base) => {
          const {type, id} = getGameData('bases')[base]
          getGameData('bases')[base].baseLoaded = false
          addOnComplete('outfielder_' + type + "_disappear_" + id, () => {
            outfielderAnimations.forEach(animation => {
              assetsRef.current['outfielder_' + type + "_" + animation + "_" + id].destroy()
              delete assetsRef.current['outfielder_' + type + "_" + animation + "_" + id]
            })
          })
        }

        const positionOutfielder = (prev, next, bases) => {
          const prevBase = getGameData('bases')[prev]
          const nextBase = getGameData('bases')[next]
          prevBase.baseLoaded = false
          const type = prevBase.type
          const id = prevBase.id
          nextBase.type = type
          nextBase.id = id
          nextBase.baseLoaded = true
        }
    
        const createOutfielder = (base) => {
          const type = randArrVal([1, 2, 3])
          const color = Math.random() * 0xFFFFFF;
          const key = 'outfielder_' + type + "_"
          const animations = outfielderAnimations.map((animation) => key + animation)
          const id = Math.floor(Math.random() * 10000000)
          animations.forEach((animation) => {
            const outfielderID = animation + "_" + id
            cloneAnimatedSprite(animation, outfielderID)
            setAnimationParams({
              assetName: outfielderID, 
              speed: 0.1, 
              x: getAsset('platform_' + base).position.x, 
              y: getAnimationData('platform_init_y_' + base) + getAnimationData('platform_delta_y_' + base), 
              scale: base === 1 ? 0.2 : 0.3, 
              play: false
            })
            getAsset(outfielderID).tint = color
            getAsset(outfielderID).visible = false
            getAsset(outfielderID).loop = false

            getGameData('bases')[base].type = type
            getGameData('bases')[base].id = id
            getGameData('bases')[base].baseLoaded = true
            getAsset('baseball_game').addChild(getAsset(outfielderID))
          })
          addOnComplete(key + 'appear_' + id, () => {
            setVisible(key + 'appear_' + id, false)
            setVisible(key + 'static_' + id, true)
            getAsset(key + 'static_' + id).loop = true
            getAsset(key + 'static_' + id).gotoAndPlay(0)
          })
          addOnComplete(key + 'disappear_' + id, () => {
            setVisible(key + 'disappear_' + id, false)
          })
          setVisible(key + 'appear_' + id, true)
          getAsset(key + 'appear_' + id).gotoAndPlay(0)
        }  

        const hitBall = () => {
          console.log('hitting ball')
          updateScore(1)
          // addTimeout(() => {updateScore(1)}, 3)
          playNext('baseball_guy_away', 'guy_hit_init')
          removeAction(hitBall)
        }

        const throwBall = () => {
          console.log('throwing ball')
          playNext('pitch_stand', 'wind_up')          
        }

        const addWindowEvents = () => {
          addAction(hitBall)
        }

        const addThrowCompleteEvents = () => {
          [1, 2, 3].forEach(num => {
            addOnComplete('throw_' + num + '_init', () => {
              const throwtime = window.Date.now()
              console.log(gameRef.current)
              const hittime = getGameData('hittime')
              if (hittime) {
                console.log("diff: " + (throwtime - hittime))
                const diff = (throwtime - hittime)
                if (diff < 150) {
                  playNext('throw_' + num + '_init', 'hit_' + randArrVal([1, 2, 3]))
                } else {
                  playNext('throw_' + num + '_init', 'throw_' + num + '_release')
                }
              } else {
                playNext('throw_' + num + '_init', 'throw_' + num + '_release')
              }
            })
            addOnComplete('throw_' + num + '_release', () => {
              setVisible('throw_' + num + '_release', false)
            })
            addOnComplete('hit_' + num, () => {
              setVisible('hit_' + num, false)
            })
          })
        }
        const addOnCompleteEvents = () => {
          addOnComplete('guy_hit_init', () => {
            playNext('guy_hit_init', 'guy_hit_release')
            setGameData('hittime', window.Date.now())
          })
          addOnComplete('guy_hit_release', () => {
            setVisible('guy_hit_release', false)
            setVisible('baseball_guy_away', true)
            addAction(hitBall)
          })
          addOnComplete('wind_up', () => {
            playNext('wind_up', 'release')
            playNext('wind_up', 'throw_' + randArrVal([1, 2, 3]) + "_init")
          })
          addOnComplete('release', () => {
            setVisible('release', false)
            addTimeout(() => {playNext('release', 'appear')}, 1.0)
          })
          addOnComplete('appear', () => {
            playNext('appear', 'pitch_stand')
            addTimeout(throwBall, 1.0)
          })
          addThrowCompleteEvents()
        }
        hideSprites()
        addWindowEvents()
        addOnCompleteEvents()
        addTimeout(throwBall, 3.0)
      }
  
      buildLevel(
        update, 
        [
          'moon',
          'planet', 
          'mountains', 
          'field',
          'pitchers_mound',
          'homebase',
          'platform_0',
          'platform_1',
          'platform_2',
          'pitch_stand',
          'wind_up',
          'release',
          'appear',
          'throw_1_init',
          'throw_1_release',
          'throw_2_init',
          'throw_2_release',
          'throw_3_init',
          'throw_3_release',
          'hit_1',
          'hit_2',
          'hit_3',
          "guy_hit_init",
          "guy_hit_release",
          'baseball_guy_away',
        ], 
        'baseball_game',
        getAsset('starsArr'),
        makeAnimation
      )
      
    }


    const initPoints = () => {
      setGameData('score', 0)
      setGameData('inning', 0)
      setGameData('outs', 0)
      setGameData('bases', [
        {
          baseLoaded: false,
          type: NaN,
          id: NaN
        },
        {
          baseLoaded: false,
          type: NaN,
          id: NaN        
        },
        {
          baseLoaded: false,
          type: NaN,
          id: NaN        
        }
      ])
    }

    const platformLoop = () => {
      if (containsAsset('numPlatforms')) {
        for(let i = 0; i < getAsset('numPlatforms'); i++) {
          if (containsAsset('platform_' + i)) {
            const platformPosition = getAnimationData('platform_delta_y_' + i) 
              + getAnimationData('platform_init_y_' + i)
            setPlatformPosition(i)
            getAsset('platform_' + i).position.y = platformPosition
            const updatedCounter = (getAnimationData('platform_counter_' + i)  + 1) 
              % getAnimationData('platform_speed_' + i)
            setAnimationData('platform_counter_' + i, updatedCounter)
            if (getGameData('bases')[i].baseLoaded) {
              outfielderAnimations.forEach(animation => {
                  const {type, id} = getGameData('bases')[i]
                  const key = 'outfielder_' + type + "_" + animation + "_" + id
                  getAsset(key).position.y = platformPosition - (getAsset('platform_' + i).height * 0.58)
                  getAsset(key).position.x = getAsset('platform_' + i).position.x
                  const scale = (i === 1) ? 0.2 : 0.3 
                  getAsset(key).width = scale * 512
                  getAsset(key).height = scale * 512
                  getAsset(key).animationSpeed = 0.1
              })
            }
          }
        }
      }
    }

    // const createHUD = () => {
    // }

    const gameLoop = (delta) => {
      platformLoop()
    } 
  

    if (!appRef.current) {

        const buildApplication = () => {
          appRef.current = new PIXI.Application({
            width: window.innerWidth, 
            height: window.innerHeight,
            antialias: true
          })
          const app = appRef.current
          divRef.current.append(app.view)
        }
    
        const setup = async (loader, resources) => {
          let message = 'taking practice swings'
          alterLoadingText(message, 3, 1.0)
          const animatedAssets = assetsRef.current['animatedData']
          for (let i = 0; i < animatedAssets.length; i++) {
            await buildAnimatedSpirte(animatedAssets[i], resources)
          }
          alterLoadingText(message, 3, 2.0)
          const staticAssets = assetsRef.current['staticData']
          for (let i = 0; i < staticAssets.length; i++) {
            assetsRef.current[staticAssets[i].key] = new PIXI.Sprite(
              resources[staticAssets[i].key].texture
            )
          }
          initPoints()
          buildBaseballGame(resources)
          alterLoadingText(message, 3, 3.0)
          appRef.current.ticker.add(gameLoop)
          assetsRef.current['loadingScreen'].visible = false
          setAsset('gameLoaded', true)
        }
        // build the application
        buildApplication()

        // load assets urls from s3 bucket
        buildLoadingScreen(appRef.current, width, height)
        assetsRef.current['animatedData'] = await loadAssets({
          type: "animated", 
          items: animatedSprites, 
          message: "downloading universe"
        })
        assetsRef.current['staticData'] = await loadAssets({
          type: "static", 
          items: textures,
          message: "summoning cosmic baseball"
        })
        let progress = 0.0
        let goal = animatedSprites.length + textures.length
        PIXI.Loader.shared.onLoad.add(() => {
          alterLoadingText(
            "refining groove", 
            goal, 
            progress + 1.0
          )
          progress += 1.0
        })

        PIXI.Loader.shared.load(setup)
        /*
        const baseballURL = await Storage.get('game-assets/baseball.png')
        const starURL = await Storage.get('game-assets/star3.png')
        async function setup (loader, resources) {
          const baseballJSONURL = await Storage.get('game-assets/baseball.json')
          let jsonURL = await fetch(baseballJSONURL)
          const data = await jsonURL.json()
          const spritesheet = new PIXI.Spritesheet(
            resources["baseball.png"].texture,
            data
          );
          // const sound = await Storage.get("theatsoundshars.mp3")
          // const analyzer = new Tone.Analyser('waveform')
          // const player = new Tone.Player(sound).chain(analyzer, Tone.Destination)
          // player.fadeIn = 20.0
          // player.fadeOut = 20.0
          // player.autostart = true
          // player.loop = true

          spritesheet.parse((...args) => {
            const frameCount = Object.keys(data.frames).length
            const frames = args[0];
            console.log(frames)
            const textures = [];
            for (let i = 0; i < frameCount; i++) {
              let frame =  "000" + i;
              if (i < 10) {
                frame = "0" + frame
              }
              textures.push(frames["Baseball_" + frame + ".png"])
            }
            const sprite = new PIXI.AnimatedSprite(textures)
            sprite.loop = false
            sprite.animationSpeed = 0.75
            sprite.anchor.x = 0.5
            sprite.anchor.y = 0.5
            sprite.width = 300
            sprite.height = 300

            const starTexture = resources['star.png'].texture;
            const starAmount = 2000;
            let cameraZ = 0;
            const fov = 20;
            const baseSpeed = 0.3;
            let speed = 0.025;
            let warpSpeed = 0;
            const starStretch = 1;
            const starBaseSize = 0.0075;
      
      
            // Create the stars
            const stars = [];
            for (let i = 0; i < starAmount; i++) {
                const star = {
                    sprite: new PIXI.Sprite(starTexture),
                    z: 0,
                    x: 0,
                    y: 0,
                };
                star.sprite.anchor.x = 0.5;
                star.sprite.anchor.y = 0.7;
                randomizeStar(star, true);
                app.stage.addChild(star.sprite);
                stars.push(star);
            }
            app.stage.addChild(sprite)
      
            function randomizeStar(star, initial) {
                star.z = initial ? Math.random() * 2000 : cameraZ + Math.random() * 1000 + 2000;
                const deg = Math.random() * Math.PI * 2;
                const distance = Math.random() * 50 + 1;
                star.x = Math.cos(deg) * distance;
                star.y = Math.sin(deg) * distance;
            }

            const blinkEvent = () => {
              setTimeout(() => {
                sprite.gotoAndPlay(0)
                blinkEvent()
              }, ((Math.random() * 10.0 + 0.5) * 1000))
            }
      
            blinkEvent()
            // Listen for animate update
            let phaseX = 0.0
            let phaseY = 0.0
            const frameRate = 24.0
            const rotationLength = 10.0
            const rotationAmp = 20
            const targetFramesX = frameRate * rotationLength
            const targetFramesY = frameRate * rotationLength * Math.random() * 5.0
            app.ticker.add((delta) => {
                // Simple easing. This should be changed to proper easing function when used for real.
                const output = analyzer.getValue().map(val => Math.sqrt(Math.pow(val, 2))).reduce((a, b ) => a + b)
                cameraZ += delta * 10 * (baseSpeed + (output * 0.01));
                sprite.x = app.renderer.screen.width / 2 + (Math.sin( 
                  Math.PI * 2 * (phaseX / targetFramesX)) * rotationAmp
                );
                sprite.y = app.renderer.screen.height / 3.5 + (
                  Math.sin( Math.PI * 2 * (phaseY / targetFramesY)
                ) * rotationAmp * 0.5);
                phaseX += 1.0
                phaseX = phaseX % targetFramesX
                phaseY += 1.0
                phaseY = phaseY % targetFramesY
                for (let i = 0; i < starAmount; i++) {
                    const star = stars[i];
                    if (star.z < cameraZ) randomizeStar(star);
                    const z = star.z - cameraZ;
                    star.sprite.x = star.x * (fov / z) * app.renderer.screen.width + app.renderer.screen.width / 2;
                    star.sprite.y = star.y * (fov / z) * app.renderer.screen.width + app.renderer.screen.height / 2;
                    const dxCenter = star.sprite.x - app.renderer.screen.width / 2;
                    const dyCenter = star.sprite.y - app.renderer.screen.height / 2;
                    const distanceCenter = Math.sqrt(dxCenter * dxCenter + dyCenter * dyCenter);
                    const distanceScale = (Math.max(0, (2000 - z) / 2000));
                    star.sprite.scale.x = distanceScale * starBaseSize;
                    star.sprite.scale.y = distanceScale * starBaseSize + distanceScale * speed * starStretch * distanceCenter / app.renderer.screen.width;
                    star.sprite.rotation = Math.atan2(dyCenter, dxCenter) + Math.PI / 2;
                }
            });
          });
        }
        PIXI.Loader.shared
        .add("baseball.png", baseballURL)
        .add("star.png", starURL)
        .load(setup)
        */
    } else {
      appRef.current.renderer.resize(width, height)
      buildLoadingScreen(appRef.current, width, height)
      if (containsAsset('gameLoaded')) {
        buildBaseballGame()
      }
    }
  }, [height, width])

  useEffect(() => {
    makeGame()
  }, [width, height, makeGame])

  return (
    <div style={{position: "fixed", left: 0,top: 0}} width={width} height={height} ref={divRef}>
    </div>
  );
};

export default PIXIContainer;
import './App.css';
import { Routes, Route } from "react-router-dom";
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import PIXIContainer from './components/PIXIContainer'

Amplify.configure(awsconfig)

function App() {
  return (
    <>
      {/* <PIXIContainer/> */}
      
      <Routes>
        <Route path="/" element={<PIXIContainer/>} />
      </Routes>
    </>
  );
}

export default App;
